import React from 'react'
import RenderContent from '../../RenderContent';
import { Image } from '../../Image'
import './UspContent.scss'

export const UspContent = ({ leftContent, rightContent, rightBackgroundImage = null }) => {
  return (
    <section className="usp-content">
      <div className="wrap">
        <div className="inner">
            <div className="left"><RenderContent content={leftContent} /></div>
            <div className="right">
                <RenderContent content={rightContent} />
                <div className="background">
                  <Image src={rightBackgroundImage}/>
                </div>
            </div>
        </div>
      </div>
    </section>
  )
}
