import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './TestimonialSlider.scss'
import Carousel from '../../Carousel'
import GatsbyLink from '../../GatsbyLink'
import OnVisible from 'react-on-visible'
import { slugify, decodeEntities } from '../../../utils/helpers'
import { Image } from '../../Image'

export const TestimonialItem = props => {
  const { showLogo, slide, className, slideView = false } = props
  return (
    <div
      className={`${className}${
        showLogo ? ' logo-testimonial' : ' testimonial'
      }`}
    >
      <div className="wrap">
        <div className="inner">
          {showLogo && (
            <div className="logo">
              <div className="inner-logo">
                {slide?.acf?.logo && <Image src={slide.acf.logo && slide.acf.logo} />}
              </div>
            </div>
          )}
          <div className="content">
            {slide.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(slideView ? `${slide.excerpt}...`: slide.content),
                }}
              ></div>
            )}
            <span className="name">{decodeEntities(slide.title)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

class ThisSlider extends Component {
  render() {
    const { testimonials, showLogo, title, listView } = this.props
    const settings = {
      container: `${slugify(title)}-slug`,
      nav: true,
      controls: true,
      loop: true,
      mouseDrag: true,
      items: 1,
    }
    return (
      <div className={!listView ? 'carousel-wrap' : 'list-wrap'}>
        {!listView && (
          <Carousel settings={settings}>
            {testimonials.map(
              (slide, slide_count) =>
                slide_count < 6 && (
                  <TestimonialItem
                    key={slide_count}
                    slide={slide}
                    showLogo={showLogo}
                    className="slide"
                    slideView={true}
                  />
                )
            )}
          </Carousel>
        )}
        {listView &&
          testimonials.map((slide, slide_count) => (
            <TestimonialItem
              key={slide_count}
              slide={slide}
              showLogo={showLogo}
              className="slide"
            />
          ))}
      </div>
    )
  }
}

export const TestimonialPass = props => {
  const { title, showLogo, data, listView } = props
  const { allWordpressWpTestimonials } = data
  const { nodes: posts } = allWordpressWpTestimonials
  return (
    <section className={`testimonial-slider`}>
      {title && <span className="section-title h3">{decodeEntities(title)}</span>}
      <ThisSlider
        testimonials={posts}
        showLogo={showLogo}
        title={title}
        listView={listView}
      />
      {!listView && <div className="container action">
        <div className="wrap">
          <GatsbyLink
            className="action"
            to="/testimonials/"
            aria-label="View all"
            // decode={true}
          >
            View all
          </GatsbyLink>
        </div>
      </div> }
    </section>
  )
}

export const TestimonialSlider = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              excerpt
              slug
              title
              acf {
                logo {
                  source_url
                }
                position
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  )
}
