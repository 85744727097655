import React from 'react'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'

import './TextImageBlock.scss'

export const TextImageBlock = ({ imageBlockContent, textBlockContent }) => {
  const image = imageBlockContent[0]
  const text = textBlockContent[0] 
  return (
    <section className="text-image-block">
      <div className="wrap">
        <div
          className={`container ${image.options} text-${text.sectionPoisitioning}`}
        >
          <div className="text-container">
            <h3 className="section-title">{text.sectionTitle}</h3>
            <p className="subtitle">{text.sectionSubTitle}</p>
            <RenderContent content={text.sectionContent} />
            {text.sectionAction && (
              <GatsbyLink
                className="action"
                to={text.sectionAction.url}
                aria-label="Learn more"
                // decode={true}
              >
                {text.sectionAction.title}
              </GatsbyLink>
            )}
          </div>
          <div className="image-container">
            <Image className="image" src={image.sectionImage} />
          </div>
        </div>
      </div>
    </section>
  )
}
