import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import * as AcfLayout from '../components/Acf';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/helpers';
import { RenderShortcode } from '../components/RenderShortcode';
import { SideNavigation } from '../components/SideNavigation';
import { PageHeader } from '../components/PageHeader';
import GatsbyLink from '../components/GatsbyLink'
import RenderContent from '../components/RenderContent';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }

};

const Page = ({ data, location, pageContext }) => {
  if (!data) return null;
  const { wordpressPost: page, site, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  if (!page) return null;
  const { title, featured_media, template = 'template-pagebuilder.php', content, yoast, acf = {} } = page;
  const { title: siteTitle } = wordpressWpSettings;
  if (template === 'template-pagebuilder.php') {
    return (
      <Layout location={location} wordpressUrl={wordpressUrl}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
        />
        {acf && acf.layout && acf.layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace('WordPressAcf_','');
          return (
            <AcfComponent
              key={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
            />
          );
        })}
      </Layout>
    )
  } else {
    return (
      <Layout location={location} wordpressUrl={wordpressUrl}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
        />
        <div className="default-page-template">
          <PageHeader headerTitle={title} headerSubTitle={null} headerBackgroundImage={featured_media} location={location} />
          <div className="content">
            <div className="wrap">
              <div className="inner">
                <RenderShortcode content={content}/>
                <div className="side-blocks">
                  
                  {acf?.sidebarBlocks && acf.sidebarBlocks.map((block, index) => 
                    <div className={`block ${block.style}`} key={index}>
                      <div className="entry-content">
                        {block.title && <span class="block-title" dangerouslySetInnerHTML={{__html: block.title}} />}
                        <RenderContent content={block.content}/>
                      </div>
                    </div>
                  )}
                  
                  <SideNavigation pageContext={pageContext}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
    
  }
}

export default Previewable(Page, 'page');
//export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ... GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      template
      acf {
        sidebarBlocks {
          title
          style
          content
        }
        layout: layout_page {
          __typename
          ... FlexibleBlockQuery
          ... HeaderQuery
          ... TestimonialSliderQuery
          ... BlogFeedQuery
          ... ConcertinaQuery
          ... GalleryQuery
          ... StaffProfilesQuery
          ... LogoFeedQuery
          ... LinkListQuery
          ... ServiceCardQuery
          ... UspBannerQuery
          ... TextImageBlockQuery
          ... VarietyCTAQuery
          ... ProductCategoryQuery
          ... UspContentQuery
        }
      }
    }
  }
`
