import React, { Component } from 'react'
import './Header.scss'
import { Image } from '../../Image'
import HomePageImageQuery from '../../Image/HomePageImageQuery'
import GatsbyLink from '../../GatsbyLink'
import Carousel from '../../Carousel'
import { Breadcrumbs } from '../../Breadcrumbs'
import { decodeEntities } from '../../../utils/helpers'

class HeaderSlider extends Component {
  render() {
    const { slider, globalTitle, location, breadcrumbs, centre } = this.props
    const settings = {
      container: 'page-carousel',
      nav: true,
      mouseDrag: true,
      items: 1,
      controls: false,
      rewind: true,
      loop: false,
      mode: 'gallery',
      autoplay: globalTitle ? true : false,
    }
    return (
      <div className="page-slider">
        <Carousel settings={settings}>
          {slider.map((slide, slideCount) => (
            <div key={slideCount} className="slide">
              {!globalTitle && (
                <div className={`title-block${centre ? ' centre' : ''}`}>
                  <div className="wrap">
                    <div className="inner">
                      {breadcrumbs && <Breadcrumbs location={location} />}
                      {slideCount === 0 ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      ) : (
                        <p
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      )}
                      {slide.subHeader && (
                        <span
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.subHeader),
                          }}
                        />
                      )}
                      {slide.action && (
                        <GatsbyLink
                          to={slide.action.url}
                          className="action"
                          decode={true}
                        >
                          {slide.action.title}
                        </GatsbyLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {location.pathname === "/" && slideCount == 0 ? <div className="place-holder"/> : <Image src={slide.background} className="background" /> }
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export const Header = props => {
  const { compact, globalTitle, breadcrumbs, header, subHeader, centre, location } = props
  return (
    <section className={`page-header${compact ? ' compact' : ''}`}>
      {location.pathname === "/" ? <HomePageImageQuery className="home-background"/> : null }
      <HeaderSlider {...props} />
      {globalTitle && (
        <div className={`title-block${centre ? ' centre' : ''}`}>
          <div className="wrap">
            <div className="inner">
              {breadcrumbs && <Breadcrumbs location={location} />}
              <span
                className="title"
                dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
              />
              {subHeader && (
                <span
                  className="sub-title"
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(subHeader),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
