import React from 'react'
import './UspBanner.scss'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'
import { Image } from '../../Image' 

export const UspBanner = ({ uspText, uspAction, uspContent, icon }) => {
  return (
    <section className="usp-banner">
      <div className="wrap">
        <div className="banner">
          {icon && <div className="icon-wrap"><Image src={icon} /></div>}
          <div className="content">
            <h3 className="banner-title">{uspText}</h3>
            {uspContent && <RenderContent content={uspContent}/>}
          </div>
          {uspAction && <GatsbyLink
            className="action"
            to={uspAction.url}
            aria-label="Learn more"
            // decode={true}
          >
            {uspAction.title}
          </GatsbyLink>}
        </div>
      </div>
    </section>
  )
}
