import React from 'react'
import './VarietyCTA.scss'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'

export const VarietyCTA = ({
  column1Data,
  column2Data,
  column3Data,
  column4Data,
}) => {
  const col1 = column1Data[0]
  const col3 = column3Data[0]
  const col4 = column4Data[0]
  return (
    <section className="variety-cta">
      <div className="wrap">
        <div className="container">
          {/* col 1 */}
          <div className="column column-1">
            <p className="heading-large">{col1.column1Title}</p>
            <p>{col1.column1Description}</p>
            <GatsbyLink
              className="action"
              to={col1.column1Action.url}
              aria-label="Learn more"
              // decode={true}
            >
              {col1.column1Action.title}
            </GatsbyLink>
          </div>

          {/* col 2 */}
          <div className="column column-2">
            <div className="image-container">
              <Image className="image" src={column2Data} />
            </div>
          </div>

          
          <div className="small-row">
            {/* col 3 */}
            <div className="column column-3 dark">
              {col3.column3Icon && (
                <div className="image-container icon">
                  <Image className="image" src={col3.column3Icon} />
                </div>
              )}
              <p className="heading-small">{col3.column3Title}</p>
              <GatsbyLink
                className="action"
                to={col3.column3Action.url}
                aria-label="Learn more"
                // decode={true}
              >
                {col3.column3Action.title}
              </GatsbyLink>
            </div>

            {/* col 4 */}
            <div className="column column-4 dark">
              {col4.column4Icon && (
                <div className="image-container icon">
                  <Image className="image" src={col4.column4Icon} />
                </div>
              )}

              <p className="heading-small">{col4.column4Title}</p>
              <GatsbyLink
                className="action"
                to={col4.column4Action.url}
                aria-label="Learn more"
                // decode={true}
              >
                {col4.column4Action.title}
              </GatsbyLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
