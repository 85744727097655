import React from 'react'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'

import './ServiceCards.scss'

export const ServiceCards = ({ service }) => {
  return (
    <section className="service-cards">
      <div className="wrap">
        <div className="services">
          {service.map((serv, index) => {
            return (
              <div className="service" key={index}>
                <div className="inner">
                  {serv.icon.source_url && (
                    <img className="icon" src={serv.icon.source_url} alt="" />
                  )}
                  {/* there is a zero-width space after the second / in the replace. Helping the word breaks on mobile 😒 */}
                  <h2 className="title">{serv.title.replace("/", "/​")}</h2> 
                  <p className="intro">{serv.text}</p>
                  <GatsbyLink
                    className="action"
                    to={serv.action.url}
                    aria-label="Learn more"
                    // decode={true}
                  >
                    {serv.action.title}
                  </GatsbyLink>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
