import React from 'react';
import RelatedPosts from '../../Posts/Related/RelatedPosts';
import Instagram from '../../Instagram'

export const BlogFeed = (props) => {
  if (props.contentType === 'post') {
    return <RelatedPosts {...props} />
  }
  if (props.contentType === 'instagram') {
    return <Instagram {...props} />
  }
  return null
}
